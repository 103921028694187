import React from 'react';

const Notice = () => {
  return (
    <div className="kt-notice">
      <div className="kt-container kt-container--fluid">
        <div className="kt-notice__content">
          <div className="kt-notice__text">
            <h3>Important Notice</h3>
            <p>This version of APSIS Cloud is shutting down soon. Please contact us in order to upgrade to the new version and continued access to remote devices.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
